<template>
  <div class="container mt-5">
    <h1 class="text-center">Welcome to OrderTrack</h1>
    <nav class="nav justify-content-center mt-4 flex-column flex-md-row">
      <router-link class="nav-link" to="/tags">Tag Editor</router-link>
      <router-link class="nav-link" to="/items">Item Editor</router-link>
      <router-link class="nav-link" to="/cart">Cart</router-link>
      <router-link class="nav-link" to="/orders">Order Review</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>

<style scoped>
h1 {
  font-size: 2.5em;
}
.nav-link {
  margin: 0.5em;
}
</style>
