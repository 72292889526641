import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './global.css'; // Import your global CSS file
import axiosInstance from './axiosInstance';

import { BootstrapVueNext } from 'bootstrap-vue-next';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

async function fetchConfig() {
    const response = await fetch('/config.json');
    const config = await response.json();
    return config;
  }
  

fetchConfig().then(config => {
    const app = createApp(App);
    app.use(router);
    app.provide('config', config);
    app.provide('axios', axiosInstance(config.apiBaseUrl));
    app.use(BootstrapVueNext);
    app.mount('#app');
  });