<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Order Tracker</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link" exact>Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/cart" class="nav-link">Cart</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/orders" class="nav-link">Orders</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/tags" class="nav-link">Tags</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/items" class="nav-link">Items</router-link>
            </li>
            <li class="nav-item" v-if="isAdmin">
              <router-link to="/admin" class="nav-link">Admin</router-link>
            </li>
            <li class="nav-item">
              <button class="btn btn-link nav-link" @click="logout">Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </template>
  
  <script>
  import { ref, computed, onMounted } from 'vue';
    import {jwtDecode} from 'jwt-decode';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'NavigationBar',
    setup() {
      const router = useRouter();
      const token = ref(localStorage.getItem('token'));
      const isAdmin = computed(() => {
        if (token.value) {
          const decodedToken = jwtDecode(token.value);
          return decodedToken.role === 'admin';
        }
        return false;
      });
  
      const logout = () => {
        localStorage.removeItem('token');
        router.push('/login');
      };
  
      onMounted(() => {
        const decodedToken = jwtDecode(token.value);
        if (decodedToken.exp < Date.now() / 1000) {
          logout();
        }
      });
  
      return {
        isAdmin,
        logout
      };
    }
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  