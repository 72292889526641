// src/axiosInstance.js
import axios from 'axios';
import router from './router'; // Assuming you have Vue Router set up

export default function createAxiosInstance(apiBaseUrl) {
  const axiosInstance = axios.create({
    baseURL: apiBaseUrl,
  });

  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  axiosInstance.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('token');
      router.push('/login'); // Redirect to login page if token is invalid
    }
    return Promise.reject(error);
  });
  return axiosInstance;
}
