<template>
  <div class="container mt-5">
    <h2>Orders</h2>
    <ul class="list-group mb-3" v-if="orders.length">
      <li class="list-group-item" v-for="(order, index) in orders" :key="index">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div>
            Order #{{ order._id }} - {{ order.items.length }} items<br />
            Customer: {{ order.customerInfo.firstName }} {{ order.customerInfo.lastName }}<br />
            Agent: {{ order.customerInfo.agentName }}<br />
            Address: {{ order.realEstateInfo.address }}
          </div>
          <div>
            <button @click="reviewOrder(index)" class="btn btn-primary btn-sm mr-2">Review</button>
            <button @click="confirmEditOrder(index)" class="btn btn-secondary btn-sm">Edit</button>
            <button @click="deleteOrder(order._id)" class="btn btn-danger btn-sm">Delete</button>
          </div>
        </div>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Item Name</th>
              <th scope="col">Properties</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, itemIndex) in order.items" :key="itemIndex">
              <td>{{ item.name }}</td>
              <td>
                <ul class="list-unstyled mb-0">
                  <li v-for="(value, key) in item.properties" :key="key">{{ key }}: {{ value }}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    </ul>
    <p v-else>No orders found.</p>

    <!-- Review Order Modal -->
    <div class="modal fade" id="reviewOrderModal" tabindex="-1" aria-labelledby="reviewOrderModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="reviewOrderModalLabel">Review Order</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="selectedOrder">
            <div class="compact-info mb-2">
              <h6 class="title-row">Customer: {{ selectedOrder.customerInfo?.firstName || '' }} {{
                selectedOrder.customerInfo?.lastName || '' }}</h6>
              <div class="row">
                <div class="col-6"><strong>Email:</strong> {{ selectedOrder.customerInfo?.email || '' }}</div>
                <div class="col-6"><strong>Phone:</strong> {{ selectedOrder.customerInfo?.phoneNumber || '' }}</div>
              </div>
              <h6 class="title-row">Agent: {{ selectedOrder.customerInfo?.agentName || '' }}</h6>
              <div class="row">
                <div class="col-6"><strong>Email:</strong> {{ selectedOrder.customerInfo?.agentEmail || '' }}</div>
                <div class="col-6"><strong>Phone:</strong> {{ selectedOrder.customerInfo?.agentPhoneNumber || '' }}
                </div>
              </div>
              <h6 class="title-row">Project: {{ selectedOrder.realEstateInfo?.projectTitle || '' }}</h6>
              <div class="row">
                <div class="col-6"><strong>Address:</strong> {{ selectedOrder.realEstateInfo?.address || '' }}</div>
                <div class="col-6"><strong>Unit:</strong> {{ selectedOrder.realEstateInfo?.unit || '' }}</div>
              </div>
            </div>
            <h6>Order Items</h6>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Item Name</th>
                  <th scope="col">Properties</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in selectedOrder.items" :key="index">
                  <td>{{ item.name }}</td>
                  <td>
                    <ul class="list-unstyled mb-0">
                      <li v-for="(value, key) in item.properties" :key="key">{{ key }}: {{ value }}</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" @click="generateExcel" class="btn btn-primary">Generate and Download Excel</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, inject } from 'vue';
import * as bootstrap from 'bootstrap';
import * as XLSX from 'xlsx';

export default {
  name: 'OrderReviewPage',
  setup() {
    const axios = inject('axios');
    const orders = ref([]);
    const selectedOrder = ref(null);
    const excelHref = ref('');
    const excelFilename = ref('order.xlsx');

    const fetchOrders = async () => {
      try {
        const response = await axios.get('/api/orders');
        orders.value = response.data;
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    const reviewOrder = (index) => {
      selectedOrder.value = { ...orders.value[index] };
      const reviewOrderModal = new bootstrap.Modal(document.getElementById('reviewOrderModal'));
      reviewOrderModal.show();
    };

    const confirmEditOrder = (index) => {
      const orderToEdit = orders.value[index];
      if (sessionStorage.getItem('cart')) {
        if (confirm('You have an active cart. Do you want to clear it and edit the selected order instead?')) {
          sessionStorage.removeItem('cart');
          sessionStorage.setItem('editingOrder', JSON.stringify(orderToEdit));
          window.location.href = '/cart'; // Adjust the URL based on your routing
        }
      } else {
        sessionStorage.setItem('editingOrder', JSON.stringify(orderToEdit));
        window.location.href = '/cart'; // Adjust the URL based on your routing
      }
    };

    const deleteOrder = async (orderId) => {
      try {
        await axios.delete(`/api/orders/${orderId}`);
        fetchOrders();
      } catch (error) {
        console.error('Error deleting order:', error);
      }
    };

    const generateExcel = () => {
      const customerInfo = selectedOrder.value.customerInfo || {};
      const realEstateInfo = selectedOrder.value.realEstateInfo || {};
      const orderData = [
        ['Customer Information'],
        ['First Name', customerInfo.firstName || ''],
        ['Last Name', customerInfo.lastName || ''],
        ['Email', customerInfo.email || ''],
        ['Phone Number', customerInfo.phoneNumber || ''],
        ['Agent Name', customerInfo.agentName || ''],
        ['Agent Email', customerInfo.agentEmail || ''],
        ['Agent Phone Number', customerInfo.agentPhoneNumber || ''],
        [],
        ['Real Estate Information'],
        ['Address', realEstateInfo.address || ''],
        ['Project Title', realEstateInfo.projectTitle || ''],
        ['Unit', realEstateInfo.unit || ''],
        [],
        ['Order Items'],
        ['Item Name', 'Properties']
      ];

      selectedOrder.value.items.forEach(item => {
        const properties = Object.entries(item.properties).map(([key, value]) => `${key}: ${value}`).join(', ');
        orderData.push([item.name, properties]);
      });

      const worksheet = XLSX.utils.aoa_to_sheet(orderData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Order');

      const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);

      excelHref.value = url;
      excelFilename.value = `order_${selectedOrder.value._id}.xlsx`;

      // Simulate click on download link
      const link = document.createElement('a');
      link.href = url;
      link.download = excelFilename.value;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    fetchOrders();

    return {
      orders,
      selectedOrder,
      reviewOrder,
      confirmEditOrder,
      deleteOrder,
      generateExcel,
      excelHref,
      excelFilename
    };
  }
};
</script>

<style scoped>
.compact-info {
  font-size: 0.9rem;
}
.row {
  margin-bottom: 0.5rem;
}
.title-row {
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
}
</style>
