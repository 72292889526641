<template>
    <div class="container mt-5">
      <h2>Reset Password</h2>
      <div class="mb-3">
        <input v-model="email" class="form-control" placeholder="Email" />
      </div>
      <div class="mb-3">
        <input v-model="password" type="password" class="form-control" placeholder="New Password" />
      </div>
      <div class="mb-3">
        <input v-model="token" class="form-control" placeholder="Reset Token" />
      </div>
      <button @click="resetPassword" class="btn btn-primary">Reset Password</button>
    </div>
  </template>
  
  <script>
  import { ref, inject, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  
  export default {
    name: 'ResetPasswordPage',
    setup() {
      const axios = inject('axios');
      const route = useRoute();
  
      const email = ref('');
      const password = ref('');
      const token = ref('');
  
      onMounted(() => {
        // Get the token from the URL query parameters
        const resetToken = route.query.token;
        if (resetToken) {
          token.value = resetToken;
        }
      });
  
      const resetPassword = async () => {
        try {
          await axios.post('/api/auth/reset-password', { email: email.value, password: password.value, token: token.value });
          window.location.href = '/login';
        } catch (error) {
          console.error('Error resetting password:', error);
        }
      };
  
      return {
        email,
        password,
        token,
        resetPassword
      };
    }
  };
  </script>
  