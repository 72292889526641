<template>
  <div id="app">
    <NavigationBar v-if="isAuthenticated" />
    <router-view />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import {jwtDecode} from 'jwt-decode';
import NavigationBar from './components/NavigationBar.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'App',
  components: {
    NavigationBar
  },
  setup() {
    const router = useRouter();
    const isAuthenticated = ref(false);

    const checkTokenExpiry = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp < currentTime) {
            // Token is expired
            localStorage.removeItem('token');
            router.push('/login');
            isAuthenticated.value = false;
          } else {
            isAuthenticated.value = true;
          }
        } catch (error) {
          console.error('Invalid token', error);
          localStorage.removeItem('token');
          router.push('/login');
          isAuthenticated.value = false;
        }
      } else {
        isAuthenticated.value = false;
      }
    };

    onMounted(() => {
      checkTokenExpiry();
    });

    return {
      isAuthenticated
    };
  }
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
