<template>
  <div class="container mt-5">
    <h2>Item Editor</h2>
    <div class="mb-3">
      <input v-model="searchQuery" @input="filterItems" class="form-control" placeholder="Search by item name" />
    </div>
    <div class="mb-3">
      <div class="tag-list">
        <span v-for="tag in allTags" :key="tag" :class="['badge', 'mr-1', activeTags.includes(tag) ? 'badge-active' : 'badge-secondary']" @click="toggleTagFilter(tag)">
          {{ tag }}
          <span v-if="activeTags.includes(tag)" aria-hidden="true">&times;</span>
        </span>
      </div>
    </div>
    <button @click="openModal" class="btn btn-primary mb-3">Add Item</button>
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(item, index) in filteredItems" :key="index">
        {{ item.name }} ({{ item.serialNumber }})
        <div>
          <button @click="showQRCodeModal(item.serialNumber)" class="btn btn-secondary btn-sm mr-2">QR Code</button>
          <button @click="editItem(index)" class="btn btn-secondary btn-sm mr-2">Edit</button>
          <button @click="removeItem(index)" class="btn btn-danger btn-sm mr-2">Remove</button>
          <button v-if="activeCart" @click="addToCart(item)" class="btn btn-success btn-sm">Add to Cart</button>
        </div>
      </li>
    </ul>

    <!-- Modal -->
    <div class="modal fade" id="itemModal" tabindex="-1" aria-labelledby="itemModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="itemModalLabel">Item Editor</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="input-group mb-3">
              <input v-model="formData.name" class="form-control" placeholder="Item Name" />
            </div>
            <div class="mb-3">
              <label>Serial Number</label>
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ formData.serialNumber }}</span>
                <button @click="showQRCodeModal(formData.serialNumber)" class="btn btn-secondary btn-sm">QR Code</button>
              </div>
            </div>
            <div class="tag-list mb-3">
              <span v-for="tag in formData.tags" :key="tag" class="badge badge-secondary mr-1">
                {{ tag }}
                <button type="button" class="close" aria-label="Close" @click="removeTag(tag)">
                  <span aria-hidden="true">&times;</span>
                </button>
              </span>
            </div>
            <div class="input-group mb-3">
              <input v-model="tagQuery" @input="autocompleteTags" class="form-control" placeholder="Add tags" />
              <ul class="autocomplete-list" v-if="autocompleteTagsList.length">
                <li v-for="tag in autocompleteTagsList" :key="tag._id" @click="addTagToForm(tag.name)">
                  {{ tag.name }}
                </li>
              </ul>
            </div>
            <div class="mb-3">
              <div v-for="(values, key) in formData.properties" :key="key" class="mb-2">
                <label>{{ key }}</label>
                <div v-for="(value, index) in values" :key="index" class="input-group mb-1">
                  <input v-model="formData.properties[key][index]" class="form-control" placeholder="Value" />
                  <button @click="removePropertyValue(key, index)" class="btn btn-danger">Remove</button>
                </div>
                <div class="input-group">
                  <input v-model="newPropertyValue[key]" class="form-control" placeholder="Add new value" />
                  <button @click="addPropertyValue(key)" class="btn btn-secondary">Add Value</button>
                </div>
                <button @click="removeProperty(key)" class="btn btn-danger mt-2">Remove Property</button>
              </div>
              <div class="input-group mt-3">
                <input v-model="newPropertyKey" class="form-control" placeholder="Property Name" />
                <input v-model="newPropertyValueDefault" class="form-control" placeholder="Initial Value" />
                <button @click="addProperty" class="btn btn-secondary">Add Property</button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button @click="saveItem" class="btn btn-success">Save Item</button>
          </div>
        </div>
      </div>
    </div>

    <!-- QR Code Modal -->
    <div class="modal fade" id="qrCodeModal" tabindex="-1" aria-labelledby="qrCodeModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="qrCodeModalLabel">QR Code</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <img :src="qrCodeUrl" alt="QR Code" class="qr-code-image" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" @click="printQRCode" class="btn btn-primary">Print</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { reactive, toRefs, computed } from 'vue';
import { Modal } from 'bootstrap'; // Import Bootstrap's JavaScript
import QRCode from 'qrcode';
import { inject } from 'vue';

export default {
  name: 'ItemEditorPage',
  setup() {
    const axios = inject('axios');
    const state = reactive({
      items: [], // Fetch initial items from the server
      formData: {
        name: '',
        serialNumber: '',
        properties: {},
        tags: []
      },
      newPropertyKey: '',
      newPropertyValueDefault: '',
      newPropertyValue: {},
      tagQuery: '',
      autocompleteTagsList: [],
      searchQuery: '',
      activeTags: [],
      allTags: [],
      activeCart: sessionStorage.getItem('cart') !== null, // Check if there's an active cart session
      qrCodeUrl: ''
    });

    const fetchItems = async () => {
      try {
        console.log('Fetching items')
        const response = await axios.get('/api/items');
        state.items = response.data;
        state.allTags = [...new Set(state.items.flatMap(item => item.tags))];
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    const openModal = () => {
      resetForm();
      const itemModal = new Modal(document.getElementById('itemModal'));
      itemModal.show();
    };

    const saveItem = async () => {
      if (state.formData.name) {
        if (!state.formData.serialNumber) {
          state.formData.serialNumber = uuidv4();
        }
        if (state.formData._id) {
          try {
            const response = await axios.put(`/api/items/${state.formData._id}`, state.formData);
            const index = state.items.findIndex(item => item._id === state.formData._id);
            state.items.splice(index, 1, response.data);
          } catch (error) {
            console.error('Error updating item:', error);
          }
        } else {
          try {
            const response = await axios.post('/api/items', state.formData);
            state.items.push(response.data);
          } catch (error) {
            console.error('Error adding item:', error);
          }
        }
        const itemModal = Modal.getInstance(document.getElementById('itemModal'));
        itemModal.hide();
      }
    };

    const editItem = (index) => {
      state.formData = { ...state.items[index] };
      const itemModal = new Modal(document.getElementById('itemModal'));
      itemModal.show();
    };

    const removeItem = async (index) => {
      try {
        await axios.delete(`/api/items/${state.items[index]._id}`);
        state.items.splice(index, 1);
      } catch (error) {
        console.error('Error removing item:', error);
      }
    };

    const autocompleteTags = async () => {
      try {
        const response = await axios.get(`/api/tags/autocomplete/${state.tagQuery}`);
        state.autocompleteTagsList = response.data;
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    const addTagToForm = (tag) => {
      if (!state.formData.tags.includes(tag)) {
        state.formData.tags.push(tag);
        state.tagQuery = '';
        state.autocompleteTagsList = [];
      }
    };

    const removeTag = (tag) => {
      state.formData.tags = state.formData.tags.filter(t => t !== tag);
    };

    const addProperty = () => {
      if (state.newPropertyKey && !state.formData.properties[state.newPropertyKey]) {
        state.formData.properties = {
          ...state.formData.properties,
          [state.newPropertyKey]: [state.newPropertyValueDefault]
        };
        state.newPropertyKey = '';
        state.newPropertyValueDefault = '';
      }
    };

    const addPropertyValue = (key) => {
      if (state.newPropertyValue[key]) {
        state.formData.properties[key].push(state.newPropertyValue[key]);
        state.newPropertyValue[key] = '';
      }
    };

    const removeProperty = (key) => {
      const properties = { ...state.formData.properties };
      delete properties[key];
      state.formData.properties = properties;
    };

    const removePropertyValue = (key, index) => {
      state.formData.properties[key].splice(index, 1);
    };

    const resetForm = () => {
      state.formData = {
        name: '',
        serialNumber: '',
        properties: {},
        tags: []
      };
      state.newPropertyKey = '';
      state.newPropertyValueDefault = '';
      state.newPropertyValue = {};
      state.tagQuery = '';
      state.autocompleteTagsList = [];
    };

    const filterItems = () => {
      state.filteredItems = state.items.filter(item => {
        const matchesSearch = item.name.toLowerCase().includes(state.searchQuery.toLowerCase());
        const matchesTags = state.activeTags.length === 0 || state.activeTags.every(tag => item.tags.includes(tag));
        return matchesSearch && matchesTags;
      });
    };

    const toggleTagFilter = (tag) => {
      if (state.activeTags.includes(tag)) {
        state.activeTags = state.activeTags.filter(t => t !== tag);
      } else {
        state.activeTags.push(tag);
      }
      filterItems();
    };

    const generateQRCode = async (serialNumber) => {
      try {
        const qrCode = await QRCode.toDataURL(serialNumber);
        state.qrCodeUrl = qrCode;
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    };

    const showQRCodeModal = async (serialNumber) => {
      await generateQRCode(serialNumber);
      const qrCodeModal = new Modal(document.getElementById('qrCodeModal'));
      qrCodeModal.show();
    };

    const printQRCode = () => {
      const printWindow = window.open('', '_blank');
      printWindow.document.write('<html><head><title>Print QR Code</title></head><body>');
      printWindow.document.write(`<img src="${state.qrCodeUrl}" />`);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    };

    const addToCart = (item) => {
      const cart = JSON.parse(sessionStorage.getItem('cart')) || [];
      cart.push(item);
      sessionStorage.setItem('cart', JSON.stringify(cart));
    };

    const filteredItems = computed(() => {
      return state.items.filter(item => {
        const matchesSearch = item.name.toLowerCase().includes(state.searchQuery.toLowerCase());
        const matchesTags = state.activeTags.length === 0 || state.activeTags.every(tag => item.tags.includes(tag));
        return matchesSearch && matchesTags;
      });
    });

    fetchItems();

    return {
      ...toRefs(state),
      openModal,
      saveItem,
      editItem,
      removeItem,
      addToCart,
      showQRCodeModal,
      printQRCode,
      autocompleteTags,
      addTagToForm,
      removeTag,
      addProperty,
      addPropertyValue,
      removeProperty,
      removePropertyValue,
      resetForm,
      filterItems,
      toggleTagFilter,
      filteredItems
    };
  }
};
</script>
<style scoped>
.autocomplete-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
  background-color: white;
}
.autocomplete-list li {
  padding: 8px;
  cursor: pointer;
}
.autocomplete-list li:hover {
  background-color: #f0f0f0;
}
.tag-list .badge {
  display: inline-block;
  margin-bottom: 5px;
  background-color: #6c757d;
  color: white;
}
.tag-list .badge-active {
  background-color: #007bff;
  color: white;
}
.qr-code-image {
  max-width: 100%;
  height: auto;
}
</style>
