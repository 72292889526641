<template>
  <div class="modal fade" id="itemFormModal" tabindex="-1" aria-labelledby="itemFormModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="itemFormModalLabel">{{ modalTitle }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="showQrCodeScanner" class="mb-3">
            <qrcode-stream @detect="onDetect" @init="onInit" class="w-100"></qrcode-stream>
            <div class="mt-3 text-center">
              <button @click="closeQrCodeScanner" class="btn btn-secondary">Close</button>
            </div>
          </div>
          <div v-else class="mb-3">
            <div v-if="!isItemFilled">
              <input v-model="serialNumber" class="form-control" placeholder="Enter Serial Number" />
              <button @click="fetchItemBySerialNumber" class="btn btn-secondary mt-2">Fetch Item by Serial Number</button>
              <button @click="openQrCodeScanner" class="btn btn-secondary mt-2">Scan QR Code</button>
            </div>
            <div v-else>
              <label>Serial Number</label>
              <p>{{ serialNumber }}</p>
            </div>
          </div>
          <div v-if="localFormData.name" class="mb-3">
            <label>Item Name</label>
            <p>{{ localFormData.name }}</p>
          </div>
          <div v-if="localFormData.tags && localFormData.tags.length > 0" class="mb-3">
            <label>Tags</label>
            <div class="tag-list">
              <span v-for="tag in localFormData.tags" :key="tag" class="badge badge-primary mr-1">
                {{ tag }}
              </span>
            </div>
          </div>
          <div v-for="(values, key) in localFormData.properties" :key="key" class="mb-3">
            <label>{{ key }}</label>
            <select v-model="localSelectedProperties[key]" class="form-select">
              <option v-for="value in values" :key="value" :value="value">{{ value }}</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button @click="saveChanges" class="btn btn-primary">Save Changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import { QrcodeStream } from 'vue-qrcode-reader';
import { inject } from 'vue';

export default {
  name: 'ItemFormModal',
  components: {
    QrcodeStream
  },
  props: {
    modalTitle: String,
    formData: Object,
    selectedProperties: Object,
    isEditing: Boolean
  },
  setup() {
    const axiosInstance = inject('axios');
    return {
      axiosInstance
    };
  },
  data() {
    return {
      localFormData: this.formData ? { ...this.formData } : {},
      localSelectedProperties: this.selectedProperties ? { ...this.selectedProperties } : {},
      serialNumber: this.formData && this.formData.serialNumber ? this.formData.serialNumber : '',
      showQrCodeScanner: false
    };
  },
  computed: {
    isItemFilled() {
      return this.localFormData && this.localFormData.name;
    }
  },
  methods: {
    async fetchItemBySerialNumber() {
      try {
        const response = await this.axiosInstance.get(`/api/items/${this.serialNumber}`);
        this.localFormData = response.data;
        for (const key in response.data.properties) {
          this.localSelectedProperties[key] = response.data.properties[key][0]; // Default to first value
        }
        this.closeQrCodeScanner();
      } catch (error) {
        console.error('Error fetching item by serial number:', error);
      }
    },
    openQrCodeScanner() {
      this.showQrCodeScanner = true;
    },
    closeQrCodeScanner() {
      this.showQrCodeScanner = false;
    },
    onDetect(content) {
      if (content && content[0] && content[0].rawValue) {
        this.serialNumber = content[0].rawValue;
        this.fetchItemBySerialNumber();
      }
    },
    onInit(promise) {
      promise.catch(error => {
        if (error.name === 'NotAllowedError') {
          alert('Camera permission denied.');
        } else if (error.name === 'NotFoundError') {
          alert('No camera found.');
        } else {
          alert('Unexpected error.');
        }
      });
    },
    saveChanges() {
      this.$emit('save', {
        formData: this.localFormData,
        selectedProperties: this.localSelectedProperties
      });
      const modalInstance = bootstrap.Modal.getInstance(document.getElementById('itemFormModal'));
      modalInstance.hide();
    }
  },
  watch: {
    formData: {
      handler(newVal) {
        this.localFormData = newVal ? { ...newVal } : {};
        this.serialNumber = newVal && newVal.serialNumber ? newVal.serialNumber : '';
      },
      deep: true
    },
    selectedProperties: {
      handler(newVal) {
        this.localSelectedProperties = newVal ? { ...newVal } : {};
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.tag-list .badge {
  background-color: blue;
  color: white;
  display: inline-block;
  margin-bottom: 5px;
}
</style>
