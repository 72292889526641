<template>
  <div class="container mt-5">
    <h2>{{ pageTitle }}</h2>
    <div class="mb-3">
      <button @click="openAddItemModal" class="btn btn-primary">Add Item</button>
    </div>
    <div class="mb-3">
      <input v-model="customerInfo.firstName" class="form-control mb-2" placeholder="First Name" />
      <input v-model="customerInfo.lastName" class="form-control mb-2" placeholder="Last Name" />
      <input v-model="customerInfo.email" class="form-control mb-2" placeholder="Email" />
      <input v-model="customerInfo.phoneNumber" class="form-control mb-2" placeholder="Phone Number" />
      <input v-model="customerInfo.agentName" class="form-control mb-2" placeholder="Agent Name" />
      <input v-model="customerInfo.agentEmail" class="form-control mb-2" placeholder="Agent Email" />
      <input v-model="customerInfo.agentPhoneNumber" class="form-control mb-2" placeholder="Agent Phone Number" />
      <input v-model="realEstateInfo.address" class="form-control mb-2" placeholder="Real Estate Address" />
      <input v-model="realEstateInfo.projectTitle" class="form-control mb-2" placeholder="Real Estate Project Title" />
      <input v-model="realEstateInfo.unit" class="form-control mb-2" placeholder="Unit" />
    </div>
    <ul class="list-group mb-3">
      <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(item, index) in cart" :key="index">
        {{ item.name }} ({{ item.serialNumber }}) - {{ item.propertiesString }}
        <div>
          <button @click="editItem(index)" class="btn btn-secondary btn-sm mr-2">Edit</button>
          <button @click="removeFromCart(index)" class="btn btn-danger btn-sm">Remove</button>
        </div>
      </li>
    </ul>
    <div class="mt-3">
      <button @click="confirmCart" class="btn btn-success">{{ confirmButtonText }}</button>
      <button @click="clearCart" class="btn btn-secondary">Clear {{ entity }}</button>
      <button @click="cancelCart" class="btn btn-danger">Cancel</button>
    </div>

    <!-- Add/Edit Item Modal -->
    <ItemFormModal
      :modalTitle="modalTitle"
      :formData="selectedItem"
      :selectedProperties="itemProperties"
      @save="saveItemToCart"
    />
  </div>
</template>

<script>
import { ref, reactive, onMounted, inject } from 'vue';
import ItemFormModal from './ItemFormModal.vue';
import * as bootstrap from 'bootstrap';

export default {
  name: 'CartPage',
  components: {
    ItemFormModal
  },
  setup() {
    const axios = inject('axios');
    const config = inject('config');
    const cart = ref(JSON.parse(sessionStorage.getItem('cart')) || []);
    const selectedItem = ref(null);
    const itemProperties = reactive({});
    const customerInfo = reactive({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      agentName: '',
      agentEmail: '',
      agentPhoneNumber: ''
    });
    const realEstateInfo = reactive({
      address: '',
      projectTitle: '',
      unit: ''
    });
    const modalTitle = ref('');
    const pageTitle = ref('Cart');
    const confirmButtonText = ref('Confirm Cart');
    const entity = ref('cart');
    const editingOrderId = ref(null);

    const fetchItemBySerialId = async (serialId) => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/api/items/${serialId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        selectedItem.value = response.data;
        for (const key in selectedItem.value.properties) {
          itemProperties[key] = selectedItem.value.properties[key][0]; // default to first value
        }
      } catch (error) {
        console.error('Error fetching item:', error);
      }
    };

    const openAddItemModal = () => {
      modalTitle.value = 'Add Item';
      selectedItem.value = {
        name: '',
        serialNumber: '',
        properties: {},
        tags: []
      };
      Object.keys(itemProperties).forEach(key => delete itemProperties[key]);
      const modal = new bootstrap.Modal(document.getElementById('itemFormModal'));
      modal.show();
    };

    const editItem = async (index) => {
      modalTitle.value = 'Edit Item';
      const cartItem = cart.value[index];
      try {
        const response = await axios.get(`${config.apiBaseUrl}/api/items/${cartItem.serialNumber}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        selectedItem.value = response.data;
        for (const key in selectedItem.value.properties) {
          itemProperties[key] = cartItem.properties[key]; // Load the selected properties
        }
      } catch (error) {
        console.error('Error fetching item for editing:', error);
      }
      const modal = new bootstrap.Modal(document.getElementById('itemFormModal'));
      modal.show();
    };

    const saveItemToCart = ({ formData, selectedProperties }) => {
      const propertiesString = Object.entries(selectedProperties).map(([key, value]) => `${key}: ${value}`).join(', ');
      const cartItem = {
        ...formData,
        properties: selectedProperties,
        propertiesString
      };

      if (modalTitle.value === 'Add Item') {
        cart.value.push(cartItem);
      } else {
        const index = cart.value.findIndex(item => item.serialNumber === cartItem.serialNumber);
        if (index !== -1) {
          cart.value.splice(index, 1, cartItem);
        }
      }
      sessionStorage.setItem('cart', JSON.stringify(cart.value));
    };

    const removeFromCart = (index) => {
      cart.value.splice(index, 1);
      sessionStorage.setItem('cart', JSON.stringify(cart.value));
    };

    const confirmCart = async () => {
      try {
        const orderData = {
          items: cart.value,
          customerInfo,
          realEstateInfo
        };

        if (editingOrderId.value) {
          await axios.put(`${config.apiBaseUrl}/api/orders/${editingOrderId.value}`, orderData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
        } else {
          await axios.post(`${config.apiBaseUrl}/api/orders`, orderData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
        }
        cart.value = [];
        sessionStorage.removeItem('cart');
        window.location.href = '/orders'; // Adjust the URL based on your routing
      } catch (error) {
        console.error('Error confirming cart:', error);
      }
    };

    const clearCart = () => {
      cart.value = [];
      sessionStorage.removeItem('cart');
    };

    const cancelCart = () => {
      if (editingOrderId.value) {
        sessionStorage.removeItem('editingOrderId');
        window.location.href = '/orders'; // Adjust the URL based on your routing
      } else {
        window.location.href = '/'; // Adjust the URL based on your routing
      }
    };

    onMounted(() => {
      const editingOrder = JSON.parse(sessionStorage.getItem('editingOrder'));
      if (editingOrder) {
        cart.value = editingOrder.items;
        Object.assign(customerInfo, editingOrder.customerInfo);
        Object.assign(realEstateInfo, editingOrder.realEstateInfo);
        pageTitle.value = 'Edit Order';
        confirmButtonText.value = 'Save Order';
        entity.value = 'order';
        editingOrderId.value = editingOrder._id;
        sessionStorage.removeItem('editingOrder');
      }
    });

    return {
      cart,
      selectedItem,
      itemProperties,
      customerInfo,
      realEstateInfo,
      modalTitle,
      pageTitle,
      confirmButtonText,
      entity,
      fetchItemBySerialId,
      openAddItemModal,
      editItem,
      saveItemToCart,
      removeFromCart,
      confirmCart,
      clearCart,
      cancelCart
    };
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
