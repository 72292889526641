import { createRouter, createWebHistory } from 'vue-router';
import { jwtDecode } from 'jwt-decode'; // Corrected import
import Home from '../components/HomePage.vue';
import Login from '../components/LoginPage.vue';
import CartPage from '../components/CartPage.vue';
import OrderReviewPage from '../components/OrderReviewPage.vue';
import TagEditorPage from '../components/TagEditorPage.vue';
import ItemEditorPage from '@/components/ItemEditorPage.vue';
import AdminPage from '@/components/AdminPage.vue';
import RegisterPage from '@/components/RegisterPage.vue';
import ResetPasswordPage from '@/components/ResetPasswordPage.vue';

const routes = [
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordPage
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/cart',
    name: 'CartPage',
    component: CartPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders',
    name: 'OrderReviewPage',
    component: OrderReviewPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/tags',
    name: 'Tags',
    component: TagEditorPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/items',
    name: 'Items',
    component: ItemEditorPage,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next('/login');
    } else {
      try {
        const decodedToken = jwtDecode(token);
        const userRole = decodedToken.role;

        if (to.matched.some(record => record.meta.requiresAdmin)) {
          if (userRole === 'admin') {
            next();
          } else {
            next('/'); // Redirect to home if not admin
          }
        } else {
          next();
        }
      } catch (error) {
        next('/login');
      }
    }
  } else {
    next();
  }
});

export default router;
