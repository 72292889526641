<template>
  <div class="container mt-5">
    <h2>Tag Editor</h2>
    <div class="input-group mb-3">
      <input v-model="newTag" class="form-control" placeholder="Add a new tag" @keyup.enter="addTag"/>
      <div class="input-group-append">
        <button @click="addTag" class="btn btn-primary">Add Tag</button>
      </div>
    </div>
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(tag, index) in tags" :key="index">
        {{ tag.name }}
        <button @click="removeTag(tag._id)" class="btn btn-danger btn-sm">Remove</button>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, inject } from 'vue';

export default {
  name: 'TagEditorPage',
  setup() {
    const axios = inject('axios');
    const newTag = ref('');
    const tags = ref([]);

    const fetchTags = async () => {
      try {
        const response = await axios.get('/api/tags');
        tags.value = response.data;
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    const addTag = async () => {
      if (newTag.value.trim() !== '') {
        try {
          const response = await axios.post('/api/tags', { name: newTag.value.trim() });
          tags.value.push(response.data);
          newTag.value = '';
        } catch (error) {
          console.error('Error adding tag:', error);
        }
      }
    };

    const removeTag = async (tagId) => {
      try {
        await axios.delete(`/api/tags/${tagId}`);
        tags.value = tags.value.filter(tag => tag._id !== tagId);
      } catch (error) {
        console.error('Error removing tag:', error);
      }
    };

    fetchTags();

    return {
      newTag,
      tags,
      addTag,
      removeTag
    };
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
