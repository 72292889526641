<template>
    <div class="container mt-5">
      <h2>Register</h2>
      <div class="mb-3">
        <input v-model="email" class="form-control" placeholder="Email" />
      </div>
      <div class="mb-3">
        <input v-model="password" type="password" class="form-control" placeholder="Password" />
      </div>
      <div class="mb-3">
        <input v-model="token" class="form-control" placeholder="Invitation Token" />
      </div>
      <button @click="register" class="btn btn-primary">Register</button>
    </div>
  </template>
  
  <script>
  import { ref, inject, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  
  export default {
    name: 'RegisterPage',
    setup() {
      const axios = inject('axios');
      const route = useRoute();
  
      const email = ref('');
      const password = ref('');
      const token = ref('');
  
      onMounted(() => {
        // Get the token from the URL query parameters
        const invitationToken = route.query.token;
        if (invitationToken) {
          token.value = invitationToken;
        }
      });
  
      const register = async () => {
        try {
          await axios.post('/api/auth/register', { email: email.value, password: password.value, token: token.value });
          window.location.href = '/login';
        } catch (error) {
          console.error('Error registering:', error);
        }
      };
  
      return {
        email,
        password,
        token,
        register
      };
    }
  };
  </script>
  