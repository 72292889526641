<template>
    <div>
      <h2>Admin Dashboard</h2>
      <div>
        <h3>Generate Invitation</h3>
        <input v-model="inviteEmail" placeholder="Email" />
        <select v-model="inviteRole">
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </select>
        <button @click="generateInvitation">Generate Invitation</button>
        <p v-if="invitationUrl">Invitation URL: <a :href="invitationUrl" target="_blank">{{ invitationUrl }}</a></p>
      </div>
      <div>
        <h3>Generate Reset Password URL</h3>
        <input v-model="resetEmail" placeholder="Email" />
        <button @click="generateResetPasswordToken">Generate Reset Password Token</button>
        <p v-if="resetPasswordUrl">Reset Password URL: <a :href="resetPasswordUrl" target="_blank">{{ resetPasswordUrl }}</a></p>
      </div>
      <div>
        <h3>Add User</h3>
        <input v-model="newUserEmail" placeholder="Email" />
        <input v-model="newUserPassword" type="password" placeholder="Password" />
        <select v-model="newUserRole">
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </select>
        <button @click="addUser">Add User</button>
      </div>
      <div>
        <h3>User List</h3>
        <table class="table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user._id">
              <td>{{ user.email }}</td>
              <td>
                <select v-model="user.role" @change="updateUserRole(user)">
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </td>
              <td>
                <button @click="openResetPasswordModal(user)" class="btn btn-warning btn-sm">Change Password</button>
                <button @click="deleteUser(user._id)" class="btn btn-danger btn-sm">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- Reset Password Modal -->
      <div class="modal fade" id="resetPasswordModal" tabindex="-1" aria-labelledby="resetPasswordModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="resetPasswordModalLabel">Reset Password</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div>
                <p>Reset password for: {{ currentUserEmail }}</p>
                <input v-model="newPassword" type="password" class="form-control" placeholder="New Password" />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="resetPassword">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, inject, onMounted } from 'vue';
  import * as bootstrap from 'bootstrap';
  
  export default {
    name: 'AdminPage',
    setup() {
      const axios = inject('axios');
      const inviteEmail = ref('');
      const inviteRole = ref('user');
      const invitationToken = ref('');
      const invitationUrl = ref('');
      const resetEmail = ref('');
      const resetPasswordToken = ref('');
      const resetPasswordUrl = ref('');
      const newUserEmail = ref('');
      const newUserPassword = ref('');
      const newUserRole = ref('user');
      const users = ref([]);
      const currentUserEmail = ref('');
      const newPassword = ref('');
      const currentUserId = ref(null);
  
      const generateInvitation = async () => {
        try {
          const response = await axios.post('/api/auth/invite', { email: inviteEmail.value, role: inviteRole.value }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          invitationToken.value = response.data.token;
          invitationUrl.value = `${window.location.origin}/register?token=${invitationToken.value}`;
        } catch (error) {
          console.error('Error generating invitation:', error);
        }
      };
  
      const generateResetPasswordToken = async () => {
        try {
          const response = await axios.post('/api/auth/reset-password-token', { email: resetEmail.value }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          resetPasswordToken.value = response.data.token;
          resetPasswordUrl.value = `${window.location.origin}/reset-password?token=${resetPasswordToken.value}`;
        } catch (error) {
          console.error('Error generating reset password token:', error);
        }
      };
  
      const fetchUsers = async () => {
        try {
          const response = await axios.get('/api/auth/users', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          users.value = response.data;
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };
  
      const updateUserRole = async (user) => {
        try {
          await axios.put(`/api/auth/users/${user._id}`, { role: user.role }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
        } catch (error) {
          console.error('Error updating user role:', error);
        }
      };
  
      const deleteUser = async (userId) => {
        try {
          await axios.delete(`/api/auth/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          fetchUsers(); // Refresh the user list
        } catch (error) {
          console.error('Error deleting user:', error);
        }
      };
  
      const addUser = async () => {
        try {
          // Step 1: Generate invitation token
          const inviteResponse = await axios.post('/api/auth/invite', { email: newUserEmail.value, role: newUserRole.value }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          const invitationToken = inviteResponse.data.token;
  
          // Step 2: Use the invitation token to register the user
          await axios.post('/api/auth/register', {
            email: newUserEmail.value,
            password: newUserPassword.value,
            token: invitationToken
          });
  
          newUserEmail.value = '';
          newUserPassword.value = '';
          newUserRole.value = 'user';
          fetchUsers(); // Refresh the user list
        } catch (error) {
          console.error('Error adding user:', error);
        }
      };
  
      const openResetPasswordModal = (user) => {
        currentUserEmail.value = user.email;
        currentUserId.value = user._id;
        newPassword.value = '';
  
        const modal = new bootstrap.Modal(document.getElementById('resetPasswordModal'));
        modal.show();
      };
  
      const resetPassword = async () => {
        try {
          // Generate a reset password token first
          const response = await axios.post('/api/auth/reset-password-token', { email: currentUserEmail.value }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          const resetToken = response.data.token;
  
          // Use the reset password token to update the password
          await axios.post('/api/auth/reset-password', {
            email: currentUserEmail.value,
            password: newPassword.value,
            token: resetToken
          });
  
          const modal = bootstrap.Modal.getInstance(document.getElementById('resetPasswordModal'));
          modal.hide();
        } catch (error) {
          console.error('Error resetting password:', error);
        }
      };
  
      onMounted(fetchUsers);
  
      return {
        inviteEmail,
        inviteRole,
        invitationToken,
        invitationUrl,
        resetEmail,
        resetPasswordToken,
        resetPasswordUrl,
        newUserEmail,
        newUserPassword,
        newUserRole,
        users,
        currentUserEmail,
        newPassword,
        generateInvitation,
        generateResetPasswordToken,
        fetchUsers,
        updateUserRole,
        deleteUser,
        addUser,
        openResetPasswordModal,
        resetPassword
      };
    }
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  