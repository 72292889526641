<template>
    <div class="container mt-5">
      <h2>Login</h2>
      <form @submit.prevent="login">
        <div class="mb-3">
          <input v-model="email" type="email" class="form-control" placeholder="Email" required />
        </div>
        <div class="mb-3">
          <input v-model="password" type="password" class="form-control" placeholder="Password" required />
        </div>
        <button type="submit" class="btn btn-primary">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  import { ref, inject } from 'vue';
  
  export default {
    name: 'LoginPage',
    setup() {
        const axios = inject('axios');
      const email = ref('');
      const password = ref('');
  
      const login = async () => {
        try {
          const response = await axios.post('/api/auth/login', { email: email.value, password: password.value });
          localStorage.setItem('token', response.data.token);
          window.location.href = '/';
        } catch (error) {
          console.error('Error logging in:', error);
        }
      };
  
      return {
        email,
        password,
        login
      };
    }
  };
  </script>
  